import React from "react"

import Layout from "../../components/layout"
import SubServices from "../../components/screens/Services/SubServices"
import { Helmet } from "react-helmet"

const WebDesignPage = () => (
  <Layout pageInfo={{ pageName: "Web Design" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>Web Design</title>
        <meta name="description" content="Web Design" />
    </Helmet>
    <h1 className="name-sub-services">Web Design</h1>
    <div className="wrapper-box-service">
      <SubServices
        left
        image={require("../../images/jpg/sub-services-3.jpg")}
        normal="We partner with many Agencies and end-user Clients to create beautiful and conversion driven e-Commerce websites."
      />
      <SubServices
        image={require("../../images/jpg/sub-services-4.jpg")}
      >
        <p className="normal-text">
          + E-Commerce Platform Development & Integration.<br/>
          + Mobile Development.<br/>
          + Our Solution and Platform.<br/>
        </p>
      </SubServices>
    </div>
  </Layout>
)

export default WebDesignPage
