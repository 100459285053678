import React, { Component } from "react"
import {
  Image
} from "react-bootstrap"

class SubServices extends Component {
  constructor(props) {
    super(props);
    this.renderBoxSubServices = this.renderBoxSubServices.bind(this);
  }

  renderBoxSubServices() {
    let boxItem;
    if (this.props.left) {
      boxItem = (
        <div className="wrapper-box-sub-service">
          <div className="left-box-sub">
            <Image
              alt="900x500"
              className="image-box-item-services lazyload"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
              data-src={this.props.image}
            />
          </div>
          <div className="right-box-sub">
            <p className="normal-text">{this.props.normal}</p>
          </div>
        </div>
      )
    } else {
      boxItem = (
        <div className="wrapper-box-sub-service">
          <div className="right-box-sub services">
            <p className="bold-text">{this.props.bold}</p>
            {this.props.children}
          </div>
          <div className="left-box-sub services">
            <Image
              alt="900x500"
              className="image-box-item-services lazyload"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" 
              data-src={this.props.image}
            />
          </div>
        </div>
      )
    }

    return (
      <div>
        {boxItem}
      </div>
    )
  }

  render() {
    return (
      <div className="sub-services">
        {this.renderBoxSubServices()}
      </div>
    )
  }
}

export default SubServices;
